:root {
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-4: 4;
  --z-index-999: 999;

  --font-family: 'Poppins';
  --store-primary-color: #00489a;
  --store-secondary-color: #F6F9FF;
  --store-text-primary: #FFFFFF;
  --store-text-secondary: #404040;
  --store-danger-color: #fa5c7c;
  --store-background-color: #ffffff;

  --text-sold-out: 'Sold Out';
}

